import React, { useContext } from "react";
import { MediaContext } from "../../../../store/context/media-context";
import PropTypes from "prop-types";
import UniversityName from "../../../common/webinars/UniversityName";
import UniversityInfo from "../../../common/webinars/UniversityInfo";
import "./index.scss";

const UniversityItem = (props) => {
  const { data, updatePlayerState, isWaMode, heightPage, handleRouteChange } =
    props;
  const { isTabletOrSmallDesktop } = useContext(MediaContext);
  const seeDetailsPath = `/${data.pageRef.slug}`;

  const onTitleClick = () => {
    handleRouteChange(data.pageRef.slug);
  };

  return (
    <div className="UniversityItem">
      <UniversityName
        title={data.title}
        description={data.description}
        pathLink={seeDetailsPath}
        isWaMode={isWaMode}
        handleTitleClick={onTitleClick}
      />
      <UniversityInfo
        title={data.alternativePersonName ?? data.person.nameSurname}
        img={data.person.photo}
        priceOne={data.price}
        priceTwo={data.discountPrice}
        description={
          data.alternativePersonDescription ?? data.person.description
        }
        textLink={isTabletOrSmallDesktop ? "Get Special Offer" : "See details"}
        pathLink={seeDetailsPath + "?option=scroll"}
        slug={data.pageRef.slug}
        urlYoutube={data.person.videoUrl}
        updatePlayerState={updatePlayerState}
        handleRouteChange={handleRouteChange}
        isWaMode={isWaMode}
        heightPage={heightPage}
      />
    </div>
  );
};

UniversityItem.propTypes = {
  data: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  heightPage: PropTypes.number,
  handleRouteChange: PropTypes.func,
};

export default UniversityItem;
